import { ReactElement } from 'react';
import About from './components/About';
import Hero from './components/Hero';
import Approach from './components/Approach';
import ContactUs from './components/ContactUs';
// import BlurryBackground from './components/BlurryBackground';

const Home = (): ReactElement => {
  return (
    <>
      {/* <BlurryBackground /> */}
      <div className="relative">
        <Hero />
        <Approach />
        <About />
        <ContactUs />
      </div>
    </>
  )
}

export default Home