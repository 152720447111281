import Home from 'templates/Home'
import { getLayout } from 'layouts/Default'
import { BackgroundColor } from 'utils/ColorUtils'
import { NextPageWithLayout } from './_app'

const HomePage: NextPageWithLayout = () => {
  return (
    <Home />
  )
}

HomePage.backgroundColor = BackgroundColor.White
HomePage.getLayout = getLayout

export default HomePage

