import { ReactElement, useId } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import researchImage from 'public/images/icons/icon-research.png';
import diversificationImage from 'public/images/icons/icon-diversification.png';
import innovationImage from 'public/images/icons/icon-innovation.png';

function ImageClipPaths({ id, ...props }) {
  return (
    <svg aria-hidden="true" width={0} height={0} {...props}>
      <defs>
        <clipPath id={`${id}-0`} clipPathUnits="objectBoundingBox">
          <path d="M0,0 h0.729 v0.129 h0.121 l-0.016,0.032 C0.815,0.198,0.843,0.243,0.885,0.243 H1 v0.757 H0.271 v-0.086 l-0.121,0.057 v-0.214 c0,-0.032,-0.026,-0.057,-0.057,-0.057 H0 V0" />
        </clipPath>
        <clipPath id={`${id}-1`} clipPathUnits="objectBoundingBox">
          <path d="M1,1 H0.271 v-0.129 H0.15 l0.016,-0.032 C0.185,0.802,0.157,0.757,0.115,0.757 H0 V0 h0.729 v0.086 l0.121,-0.057 v0.214 c0,0.032,0.026,0.057,0.057,0.057 h0.093 v0.7" />
        </clipPath>
        <clipPath id={`${id}-2`} clipPathUnits="objectBoundingBox">
          <path d="M1,0 H0.271 v0.129 H0.15 l0.016,0.032 C0.185,0.198,0.157,0.243,0.115,0.243 H0 v0.757 h0.729 v-0.086 l0.121,0.057 v-0.214 c0,-0.032,0.026,-0.057,0.057,-0.057 h0.093 V0" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Approach = (): ReactElement => {

  const concepts = [
    {
      title: 'Diversified',
      description: 'We use a variety of different trading strategies at Nexton Capital.',
      image: diversificationImage
    },
    {
      title: 'Research Based',
      description: 'We develop & improve trading strategies that repeatedly outperform the market by following a scientific approach.',
      image: researchImage
    },
    {
      title: 'Cutting Edge',
      description: 'We invest in cutting-edge technology to develop innovative trading strategies.',
      image: innovationImage
    },
  ];
  let id = useId();

  return (
    <div className={styles.root}>
      <div className="mx-right">

        <div className={styles.head.container}>
          <h2 className={styles.head.title}>
            Our Approach
          </h2>
          <p className={styles.head.subtitle}></p>
        </div>

        <ImageClipPaths id={id} />

        <div className={styles.body.container}>
          <div className={styles.body.grid}>

            {concepts.map((concept, index) => (
              <div key={index}>
                <div className="group relative h-[17.5rem] transform overflow-hidden rounded-4xl">
                  <div
                    className={classNames(
                      'absolute top-0 left-0 right-4 bottom-6 rounded-4xl border transition duration-300 group-hover:scale-95 xl:right-6',
                      [
                        'border-black',
                        'border-black',
                        'border-black',
                      ][index % 3]
                    )}
                  />
                  <div
                    className="absolute inset-0 bg-indigo-50"
                    style={{ clipPath: `url(#${id}-${index % 3})` }}>
                    <Image
                      className="absolute inset-0 h-full w-full object-cover transition duration-300 group-hover:scale-110 bg-white"
                      src={concept.image}
                      alt=""
                      priority
                      sizes="(min-width: 1280px) 17.5rem, (min-width: 1024px) 25vw, (min-width: 768px) 33vw, (min-width: 640px) 50vw, 100vw"
                    />
                  </div>
                </div>
                <h3 className="mt-8 font-display text-xl font-bold tracking-tight text-slate-900">
                  {concept.title}
                </h3>
                <p className="mt-1 text-base tracking-tight text-slate-500 max-w-xs">
                  {concept.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Approach;

const styles = {
  root: "container flex mb-10 mx-auto max-w-6xl",
  head: {
    container: "mx-auto max-w-4xl lg:mx-0 mb-10 px-5",
    title: "font-display text-4xl font-medium tracking-tighter text-black sm:text-5xl",
    subtitle: "mt-4 font-display text-lg tracking-tight text-slate-700"
  },
  body: {
    container: "lg:col-span-3 px-5",
    grid: "grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 sm:gap-y-16 md:grid-cols-3 [&:not(:focus-visible)]:focus:outline-none"
  }
};