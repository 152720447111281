import ContactForm from 'modules/ContactForm';
import { ReactElement } from 'react';

const ContactUs = (): ReactElement => {

  return (
    <div className={styles.root}>
      <div className="mr-auto">
        <div className={styles.head.container}>
          <h2 className={styles.head.title}>
            Contact Us
          </h2>
        </div>
        <div className="space-y-6 lg:px-0 lg:col-span-9 my-8 max-w-4xl">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

const styles = {
  root: "container mb-10 mx-auto max-w-6xl",
  head: {
    container: "mx-auto max-w-4xl lg:mx-0 mb-10 px-5",
    title: "font-display text-4xl font-medium tracking-tighter text-black sm:text-5xl",
    subtitle: "mt-4 font-display text-lg tracking-tight text-slate-700"
  },
  body: {
    container: "lg:col-span-3 px-5",
    grid: "grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 sm:gap-y-16 md:grid-cols-3 [&:not(:focus-visible)]:focus:outline-none"
  }
};